.dialog-icon-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.dialog-title-container {
  align-items: center;
  display: flex;
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.content {
  white-space: pre-wrap;
}

.mat-mdc-dialog-surface:has(.dialog-loader) {
  box-shadow: none;
  background: none;
}
